import React, { Fragment, useEffect } from "react";
import ShortTeaser from "../../../../../components/ShortTeaser/index";
import SpecialTeaser from "../../../../../components/SpecialTeaser/index";
import PagePathUtility from "../../../../../libs/PagePathUtility";
import ContentLibs from "../../../../cms_content/libs/ContentLibs";
import ContentApiService from "../../../../cms_content/services/ContentApiService";
import JobLib from "../../../libs/JobFromSolrLib";
import { fetchJobsCountByTimeLimits } from "../../../services/JobsApiService";
import useSWR from "swr";
import GeneralUtility from "../../../../../libs/GeneralUtility";
import getConfig from "next/config";

const JobsTeaser = ({ jobs, showNewJobsCount, loadLastVisit, lastVisit }) => {
  const { publicRuntimeConfig } = getConfig();
  useEffect(() => {
    if (parseInt(showNewJobsCount)) {
      loadLastVisit();
    }
  }, []);

  const layoutConfig = {
    default: {
      pattern: [
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 8,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 8,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
        },
      ],
      config: {
        repeatLast: false,
      },
    },
  };

  async function fetcher(url, lastVisit) {
    const res = await fetchJobsCountByTimeLimits(lastVisit);
    return parseInt(res.result.solr?.numFound);
  }

  const layout = "default";

  let indexShift = 0;

  let newJobsCount = 0;
  if (parseInt(showNewJobsCount)) {
    let lastVisitTimestamp = lastVisit;
    if (parseInt(lastVisitTimestamp) === 0) {
      // get new jobs since midnight
      const d = new Date();
      d.setHours(0, 0, 0, 0);
      lastVisitTimestamp = Math.floor(d.getTime() / 1000);
    }

    const { data, error } = useSWR(
      ["/jobs-count", lastVisitTimestamp],
      fetcher,
      publicRuntimeConfig.globalSwrConfig
    );
    newJobsCount = data;
  }

  return (
    <div className="grid-x grid-margin-x grid-margin-y">
      {jobs &&
        jobs.map((job, index) => {
          let itemLayoutConfig = ContentLibs.getItemSizeConfigFromColumnArray(
            layoutConfig[layout],
            index + indexShift
          );

          let specialTeaser = "";
          if (
            parseInt(showNewJobsCount) &&
            parseInt(newJobsCount) > 0 &&
            index === 3
          ) {
            // create new job count element

            let tagText = "";
            if (parseInt(lastVisit) > 0) {
              tagText = "Seit letztem Besuch";
            } else {
              tagText = "Heute";
            }

            if (parseInt(newJobsCount) > 0) {
              specialTeaser = (
                <SpecialTeaser
                  sizeDesktop={itemLayoutConfig.sizes.large}
                  number={parseInt(newJobsCount)}
                  title="Neue Jobs online"
                  linkHref={"/stellenmarkt"}
                  linkText="Alle neuen Jobs"
                  tagText={tagText}
                />
              );
              indexShift++;
              itemLayoutConfig = ContentLibs.getItemSizeConfigFromColumnArray(
                layoutConfig[layout],
                index + indexShift
              );
            }
          }

          const jobDetailLink = PagePathUtility.getPathConfig(
            "jobDetailFromSolr",
            { ...job }
          );

          const teaserImage = JobLib.getTeaserImage(job);
          const employerLogoUid = JobLib.getEmployerLogoMasked(job);
          const employerLogo = employerLogoUid
            ? ContentApiService.getImageUrl(employerLogoUid, { height: 100 })
            : "";
          // const employerLogoDimensionCategory = GeneralUtility.categorizeImageRatio(employer.companylogoMasked?.originalWidth, employer.companylogoMasked?.originalHeight);

          let bottomIcons = [];
          if (job.truecities_stringM) {
            const locationsAsString = JobLib.getLocationsAsString(
              job.truecities_stringM,
              3
            );

            bottomIcons.push({ icon: "location", label: locationsAsString });
          }

          let bgImagePath = ContentApiService.getImageUrl(teaserImage, {
            width: 450,
            cropVariant: "tile",
          });
          if (
            !GeneralUtility.isServerSide() &&
            itemLayoutConfig.sizes.large >= 6
          ) {
            // if element dimensions = rect (large >= 6) => load rect cropping
            bgImagePath = ContentApiService.getImageUrl(teaserImage, {
              width: 800,
              cropVariant:
                itemLayoutConfig.sizes.large >= 6 && window.innerWidth >= 1024
                  ? "rect"
                  : "tile",
            });
          }

          return (
            <Fragment key={`job-teaser-${index}`}>
              {specialTeaser}
              <ShortTeaser
                sizeLarge={itemLayoutConfig.sizes.large}
                sizeMedium={itemLayoutConfig.sizes.medium}
                sizeSmall={itemLayoutConfig.sizes.small}
                layout={itemLayoutConfig.layout?.name}
                title={job.title}
                linkHref={jobDetailLink}
                bgImageSrc={bgImagePath}
                topImageSrc={employerLogo}
                topImageTextReplacement={
                  employerLogo ? "" : job.companyname_stringS
                }
                // topImageAdditionalClass={employerLogoDimensionCategory}
                bottomTags={bottomIcons ? bottomIcons : []}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

export default JobsTeaser;
