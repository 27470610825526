import React, { useContext } from "react";
import PropTypes from "prop-types";
import Icon from "../../../../../../components/Icon";
import styles from "./CeDataEditIcon.module.scss";

const CeDataEditIcon = ({ ceData, onClick }) => {
  return (
    <a
      className={`color-red bg-gray pd-left-3 pd-right-3 pd-bottom-1 pd-top-3 ${styles.editIcon}`}
      onClick={(e) => {
        e.preventDefault();
        onClick(ceData);
      }}
    >
      <Icon name="pencil" size="default" />
    </a>
  );
};

CeDataEditIcon.propTypes = {
  ceData: PropTypes.object,
  onClick: PropTypes.func,
};

export default CeDataEditIcon;
