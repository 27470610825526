import React from "react";
import GeneralUtility from "../../../../libs/GeneralUtility";
import Button from "../../../../components/Button";
import PagePathUtility from "../../../../libs/PagePathUtility";
import Router from "next/router";
import { connect } from "react-redux";
import {
  getBoosting,
  getLastVisit,
  getSearchHistoryInitialized,
} from "../../reducer";
import { loadHistory } from "../../operations/searchHistory";
import { fetchJobSearch, loadLastVisit } from "../../operations/search";
import { setLastVisit } from "../../actions";

const ButtonToSearchWithConfig = ({ fetchJobSearch, searchConfig, label }) => {
  const jobSearchLink = PagePathUtility.getPathConfig("jobSearch");

  const redirectToSearchWithConfig = (searchConfig) => {
    fetchJobSearch(searchConfig).then(() => {
      Router.push(jobSearchLink);
      window.scroll({
        top: 0,
        left: 0,
      });
    });
  };

  if (!searchConfig || GeneralUtility.isEmptyObject(searchConfig)) {
    return (
      <Button
        href={PagePathUtility.createLinkWithBasepath("/stellenmarkt")}
        layout="secondary"
        text="Alle Top Jobs"
      />
    );
  } else {
    return (
      <Button
        layout="secondary"
        text={label}
        onClick={redirectToSearchWithConfig.bind(this, searchConfig)}
      />
    );
  }
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  fetchJobSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonToSearchWithConfig);
