import React, {useEffect} from 'react';
import styles from './OnPageNavigation.module.scss';
import useWindowSize from "../../../../../hooks/useWindowSize";
import GeneralUtility from "../../../../../libs/GeneralUtility";

export const ONPAGE_NAVIGATON_EVENTS = {
    REFRESH: 'ONPAGE_NAVIGATON_EVENTS_REFRESH'
}

const OnPageNavigation = ({contents, mobileNav, onItemClick, onScrollEnd}) => {
    const { isMobile } = useWindowSize();

    const handleScrollTo = (e, id) => {
        if(document.querySelector('.active-onpage')) document.querySelector('.active-onpage').classList.remove('active-onpage');
        e.target.classList.add('active-onpage');

        const scrollToElement = document.querySelector(id);

        if(scrollToElement) {
            // 50 = page header size (hardcoded because of flexible header height on mobile)
            GeneralUtility.scrollTo(
                () => {
                    const scrollToElementOffset = scrollToElement.getBoundingClientRect().top
                    const onPageOffset = document.getElementById('on-page-nav').offsetHeight;

                    if(isMobile) {
                        return window.pageYOffset + scrollToElementOffset - 60;
                    }else {
                        return window.pageYOffset + scrollToElementOffset - onPageOffset;
                    }

                },
                onScrollEnd)
        }
        if(onItemClick) {
            onItemClick();
        }
    }

    /**
     * Check if on page nav item has special display rule
     * @param displayType
     * @returns {boolean}
     */
    const checkVisibilityByDisplayType = (displayType) => {
        if (!displayType || displayType === "mobile_desktop") {
            return true;
        } else if (displayType === "mobile_only" && isMobile) {
            return true;
        } else if (displayType === "desktop_only" && !isMobile) {
            return true;
        } else {
            return false;
        }
    };

    const renderOnPageItem = (id, title) => (
        <li key={`onpage-link-${id}`} className={styles.navigationItem}>
            <a
                id={`onpage-link-${id}`}
                className="hover-effect-border"
                onClick={(e) => handleScrollTo(e, `#c${id}`)}
                data-title={title}
            >
                {title}
            </a>
        </li>
    );

    const renderOnPageItemsByContentElements = (contentElements) => {
        return contentElements.reduce((previousValue, currentValue) => {
            let newValue = previousValue;
            if (
                parseInt(currentValue.properties.showInOnPageNavigation) &&
                currentValue.properties?.onPageNavigationTitle &&
                checkVisibilityByDisplayType(
                    currentValue.properties?.onPageNavigationDisplayVariant
                )
            ) {
                newValue = [
                    ...newValue,
                    renderOnPageItem(
                        currentValue.id,
                        currentValue.properties?.onPageNavigationTitle
                    ),
                ];
            }

            if (currentValue.CType.startsWith("container_")) {
                const column2ToCheck = ["column_0", "column_1", "column_2", "column_3"];
                for (const columnKey of column2ToCheck) {
                    if (Array.isArray(currentValue.contentParts?.[columnKey]?.contents)) {
                        const columnItems = renderOnPageItemsByContentElements(
                            currentValue.contentParts[columnKey].contents
                        );
                        if (Array.isArray(columnItems) && columnItems.length > 0) {
                            newValue = [...newValue, ...columnItems];
                        }
                    }
                }
            }

            return newValue;
        }, []);
    };

    useEffect(() => {
        let event = new Event(ONPAGE_NAVIGATON_EVENTS.REFRESH); // (2)
        document.dispatchEvent(event);
    }, [isMobile])

    return (
        <div
            className={`cell ${styles.OnPageNavigation} ${mobileNav && styles.show}`}
        >
            <ul className={`${styles.navigation}`}>
                {contents && renderOnPageItemsByContentElements(contents)}
            </ul>
        </div>
    );
}

export default OnPageNavigation;