import GeneralUtility from "../../../libs/GeneralUtility";

const createBasicSearchObject = () => {
  return {
    filter: {},
  };
};

const setSearchQuery = (query = "", searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }
  searchConfig.query = query;
  return searchConfig;
};

const setSorting = (sortDirection = "rel_desc", searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }
  searchConfig.sort = "rel_desc";
  return searchConfig;
};

const setLimit = (limit, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }
  searchConfig.resultsPerPage = parseInt(limit);
  return searchConfig;
};

const setLocation = (location, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }
  searchConfig.location = location;
  return searchConfig;
};

const setRadius = (radius, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }
  searchConfig.radius = radius;
  return searchConfig;
};

const setEmployerFilter = (employer, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }

  if (employer) {
    employer = ensureArray(employer);
    employer = employer.map((employer) => parseInt(employer));
    searchConfig.filter.employer = employer;
  }
  return searchConfig;
};

const setFieldOfLawFilter = (fieldOfLaws, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }

  if (fieldOfLaws) {
    searchConfig.filter.rechtsgebiete = ensureArray(fieldOfLaws);
  }
  return searchConfig;
};

const setLocationFilter = (locations, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }

  if (locations) {
    searchConfig.filter.geodatas = ensureArray(locations);
  }
  return searchConfig;
};

const setActivityFilter = (activities, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }

  if (activities) {
    if (activities.includes(",")) {
      activities = activities.split(",");
    }

    searchConfig.filter.activity = activities;
  }
  return searchConfig;
};

const setFederalStateFilter = (federalStates, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }

  if (federalStates) {
    if (federalStates.includes(",")) {
      federalStates = federalStates.split(",");
    }

    searchConfig.filter.federalstates = federalStates.map((item) =>
      parseInt(item)
    );
  }
  return searchConfig;
};

const setTopJobFilter = (onlyTopJobs, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }

  searchConfig.onlyTopJobs = !!onlyTopJobs;
  return searchConfig;
};

const setStartpageTopJobFilter = (onlyStartpageTopJobs, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }

  searchConfig.onlyStartpageJobs = !!onlyStartpageTopJobs;
  return searchConfig;
};

const setCareerLevelFilter = (careerLevel, searchConfig = {}) => {
  if (GeneralUtility.isEmptyObject(searchConfig)) {
    searchConfig = createBasicSearchObject();
  }

  if (careerLevel) {
    searchConfig.filter.careerlevels = ensureArray(careerLevel);
  }
  return searchConfig;
};

const ensureArray = (value) => {
  return !Array.isArray(value) ? [value] : value;
};

export default {
  setSearchQuery,
  setLimit,
  setLocation,
  setRadius,
  setEmployerFilter,
  setActivityFilter,
  setFieldOfLawFilter,
  setLocationFilter,
  setFederalStateFilter,
  setCareerLevelFilter,
  setTopJobFilter,
  setStartpageTopJobFilter,
  setSorting,
};
