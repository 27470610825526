import React from "react";
import { fetchSearch } from "../services/JobsApiService";
import GeneralUtility from "../../../libs/GeneralUtility";
import useSWR from "swr";

const useJobs = (searchConfig, itemCount, config = {}) => {
  const { boosting = { disable: 1 }, renderDummyRecords = false } = config;

  const fetcher = async (
    url,
    itemCount,
    searchConfigSerialized,
    boostingConfig
    // searchHistoryInitialized
  ) => {
    // only render jobs after search history loaded - this prevents from fetching jobs twice
    // if (!searchHistoryInitialized) {
    //     return [];
    // }
    const res = await fetchSearch(
      searchConfig,
      !parseInt(boosting?.disable) ? boosting.config : []
    );
    const jobItems = res.result?.solr?.response?.docs;
    return {
      jobs: jobItems ? jobItems.slice(0, itemCount) : [],
      jobCount: res.result?.solr?.response?.numFound || 0,
    };
  };

  let initialData = {
    jobs: [],
    jobCount: 0,
  };

  // create dummy data to render empty teaser boxes
  if (renderDummyRecords) {
    initialData.jobs = GeneralUtility.getDummyRecords(itemCount);
  }

  const { data, error, mutate } = useSWR(
    [
      "/jobs-widget",
      itemCount,
      searchConfig && Object.keys(searchConfig).length > 0
        ? JSON.stringify(searchConfig)
        : "",
      parseInt(boosting?.disable) ? boosting.config : "",
      // searchHistoryInitialized
      // ], fetcher, {...process.env.globalSwrConfig, revalidateOnMount: true, initialData: initialData});
    ],
    fetcher,
    { ...process.env.globalSwrConfig, initialData: initialData }
  );

  return {
    data,
    error,
    mutate,
  };
};

export default useJobs;
