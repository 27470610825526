import React from "react";
import styles from "./ShortTeaser.module.scss";
import Image from "../Image/index";
import Icon from "../Icon/index";
import OptionalLinkWrapper from "../OptionalLinkWrapper";

const ShortTeaser = ({
  title,
  subtitle,
  bgImageSrc,
  bgImageSrcPreload = false,
  bgImageSrcSet,
  bgImageSizes,
  topImageSrc,
  topImageTextReplacement,
  sizeLarge = "3",
  sizeMedium = "6",
  sizeSmall = "12",
  layout = "default",
  linkHref = "#",
  linkAs = "",
  bgHoverColor = "",
  showImageOnHover = true,
  data = {},
  bottomTags = [],
  badgeLabel = "",
}) => {
  let backgroundMode = "default";
  let style = {};
  let shortTeaserStyle = {};

  if (bgHoverColor && !bgImageSrc) {
    backgroundMode = "colorOnHoverNoBgImage";
    style = {
      backgroundColor: bgHoverColor,
    };
  } else if (!bgHoverColor && bgImageSrc) {
    backgroundMode = "imageAlways";

    style = {
      backgroundColor: bgHoverColor,
    };
  } else if (showImageOnHover && bgHoverColor && bgImageSrc) {
    backgroundMode = "imageOnHover";

    style = {
      backgroundColor: bgHoverColor,
    };
  } else if (!showImageOnHover && bgImageSrc && bgHoverColor) {
    backgroundMode = "colorOnHover";

    style = {
      backgroundColor: bgHoverColor,
    };
  }

  Object.keys(data).forEach((key) => {
    data[`data-${key}`] = data[key];
    delete data[key];
  });

  return (
    <div
      className={`cell small-${sizeSmall} medium-${sizeMedium} large-${sizeLarge} ${
        styles["layout-" + backgroundMode]
      } layout-${layout}`}
      {...data}
    >
      <OptionalLinkWrapper
        href={linkHref}
        className={`teaser ${styles.ShortTeaser} hover-effect-img-filter`}
        style={shortTeaserStyle}
        prefetch={false}
      >
        {badgeLabel && (
          <div className={styles.badge}>
            <span className={styles.badgeLabel}>{badgeLabel}</span>
          </div>
        )}
        {bgHoverColor && <div className={styles.bgColor} style={style} />}
        {bgImageSrc && (
          <Image
            className={`${styles.bgImage} shortbgImage`}
            src={bgImageSrc}
            nextOptimizedImage={true}
            priority={bgImageSrcPreload}
            layout={"fill"}
          />
        )}
        {topImageSrc && (
          <Image
            className={`top-image ${styles.topImage}`}
            src={topImageSrc}
            lazy={true}
          />
        )}
        {!topImageSrc && topImageTextReplacement && (
          <div className={styles.topImage}>{topImageTextReplacement}</div>
        )}
        {title && <h3 className={styles.title}>{title}</h3>}
        {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}

        {bottomTags && bottomTags.length > 0 && (
          <ul className={`${styles.bottomTags} no-list`}>
            {bottomTags.map((tag, index) => (
              <li key={index}>
                <Icon name={tag.icon ?? ""} size="default" />
                <span>{tag.label}</span>
              </li>
            ))}
          </ul>
        )}
      </OptionalLinkWrapper>
    </div>
  );
};

export default ShortTeaser;
