import React, { useContext } from "react";
import CeHeader from "../CeHeader";
import CeDataGetter from "../../../libs/CeDataGetter";
import { InView } from "react-intersection-observer";
import CeDataEditIcon from "./CeDataEditIcon";
import { PageContext } from "../../../context/PageContext";
import styles from "./CeWrapper.module.scss";

const CeWrapper = ({
  children,
  ceData,
  renderHeader = true,
  enableDefaultGridWrap = false,
  additionalClassName = "",
  layoutMapping = {},
  className = "",
  ceRef,
}) => {
  const { cTypeEditFieldMapping, editModeEnabled, openCeDataUpdateModal } =
    useContext(PageContext);

  const getLayoutClassFromLayoutMapping = (layout, layoutMapping) => {
    return layoutMapping[layout] || "";
  };

  const layoutType = ceData.properties?.layout ?? 0;
  const layoutClass =
    layoutType in layoutMapping
      ? ` ce--layout-${getLayoutClassFromLayoutMapping(
          layoutType,
          layoutMapping
        )}`
      : " ce--layout-default";
  const frameClass = ceData.properties?.frameClass
    ? ` ce--frame-${ceData.properties?.frameClass}`
    : " ce--frame-default";
  const spaceBeforeClass = ceData.properties?.spaceBeforeClass
    ? ` ce--space-before-${ceData.properties?.spaceBeforeClass}`
    : " ce--space-before-default";
  const spaceAfterClass = ceData.properties?.spaceAfterClass
    ? ` ce--space-after-${ceData.properties?.spaceAfterClass}`
    : " ce--space-after-default";
  additionalClassName = additionalClassName ? ` ${additionalClassName}` : "";

  const OptionalColumnWrapper = ({ children, wrapWithDefaultColumn }) => {
    if (!wrapWithDefaultColumn) {
      return children;
    }

    return (
      <div className="grid-x grid-margin-x ce__content-wrapper max-content">
        <div className="cell large-12 ce__content-wrapper-column">
          {children}
        </div>
      </div>
    );
  };

  const OptionalInViewListener = ({ ceData, children }) => {
    if (!parseInt(ceData.properties?.showInOnPageNavigation)) return children;

    return (
      <InView
        key={`ce-onpage-listener-${ceData.id}`}
        threshold={0.1}
        onChange={ceInView}
        className={`ce_onpage_listener`}
      >
        {children}
      </InView>
    );
  };

  const ceInView = (inView, entry) => {
    // only update if item is in view
    if (!inView) {
      return;
    }
    const onPageNav = document.getElementById("on-page-nav");
    if (onPageNav) {
      const activeItem = onPageNav.querySelector(`a[href="#c${ceData.id}"]`);
      if (activeItem) {
        const currentItem = onPageNav.querySelector("a.active-onpage");
        if (currentItem) {
          currentItem.classList.remove("active-onpage");
        }
        activeItem.classList.add("active-onpage");
      }
    }
  };

  const showEditIcon = (checkForHeader = false) => {
    if (editModeEnabled === false) {
      return false;
    }
    if (
      cTypeEditFieldMapping &&
      cTypeEditFieldMapping.hasOwnProperty(ceData.CType)
    ) {
      for (const editField of cTypeEditFieldMapping[ceData.CType].editFields) {
        const value = CeDataGetter.getCeDataByPath(ceData, editField.path);
        if (checkForHeader) {
          return value && editField.path.includes("header.title");
        }
        if (value && !editField.path.includes("header.title")) return true;
      }
      return false;
    }
  };

  return (
    <div
      className={`ce ce--${ceData.CType}${layoutClass}${frameClass}${spaceBeforeClass}${spaceAfterClass}${additionalClassName} ${className}`}
      id={"c" + ceData.id}
      key={ceData.id}
      ref={ceRef}
    >
      <OptionalColumnWrapper wrapWithDefaultColumn={enableDefaultGridWrap}>
        <OptionalInViewListener ceData={ceData}>
          {renderHeader && (
            <>
              {showEditIcon(true) ? (
                <div
                  className={`ce__header ${styles.ce__header_edit}`}
                  onClick={() => openCeDataUpdateModal(ceData)}
                >
                  <CeHeader
                    header={CeDataGetter.getCeMainHeadlineTitle(ceData)}
                    subHeader={CeDataGetter.getCeSubHeadlineTitle(ceData)}
                    link={CeDataGetter.getCeHeaderLink(ceData)}
                    headerLayout={CeDataGetter.getCeMainHeadlineLayout(ceData)}
                  />
                </div>
              ) : (
                <CeHeader
                  header={CeDataGetter.getCeMainHeadlineTitle(ceData)}
                  subHeader={CeDataGetter.getCeSubHeadlineTitle(ceData)}
                  link={CeDataGetter.getCeHeaderLink(ceData)}
                  headerLayout={CeDataGetter.getCeMainHeadlineLayout(ceData)}
                />
              )}
            </>
          )}
        </OptionalInViewListener>
        {children}
      </OptionalColumnWrapper>
      {showEditIcon() && (
        <CeDataEditIcon ceData={ceData} onClick={openCeDataUpdateModal} />
      )}
    </div>
  );
};

export default CeWrapper;
