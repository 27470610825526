import React from "react";
import Link from "next/link";
import styles from "./SpecialTeaser.module.scss";
import Button from "../Button/index";

const SpecialTeaser = ({
  title,
  number,
  linkHref,
  linkText,
  tagText,
  sizeDesktop = "3",
  sizeTablet = "6",
}) => {
  return (
    <div className={`cell medium-${sizeTablet} large-${sizeDesktop}`}>
      <div className={styles.SpecialTeaser}>
        {tagText && <span className={styles.tag}>{tagText}</span>}
        {number && <h3 className={styles.number}>{number}</h3>}
        {title && <h3 className={styles.title}>{title}</h3>}
        {linkHref && (
          <Link href={linkHref} passHref>
            <Button layout={"hollowForDark"} text={linkText} />
          </Link>
        )}
      </div>
    </div>
  );
};

export default SpecialTeaser;
